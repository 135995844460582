exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-hxi-spirit-tsx": () => import("./../../../src/pages/hxi-spirit.tsx" /* webpackChunkName: "component---src-pages-hxi-spirit-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-loesungen-tsx": () => import("./../../../src/pages/loesungen.tsx" /* webpackChunkName: "component---src-pages-loesungen-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-solution-cat-tsx": () => import("./../../../src/templates/solution-cat.tsx" /* webpackChunkName: "component---src-templates-solution-cat-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-team-member-tsx": () => import("./../../../src/templates/teamMember.tsx" /* webpackChunkName: "component---src-templates-team-member-tsx" */)
}

